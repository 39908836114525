var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-page-layout',{attrs:{"title":_vm.$t('hr.paper.add_paper')},on:{"close":_vm.leavePage},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-card',{attrs:{"disabled":_vm.loading,"flat":""}},[_c('validation-observer',{ref:"form"},[_c('v-flex',{attrs:{"xs12":""}},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.paper.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('paper-type-picker',{attrs:{"error-messages":errors},model:{value:(_vm.paper.paperType),callback:function ($$v) {_vm.$set(_vm.paper, "paperType", $$v)},expression:"paper.paperType"}})]}}])})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('validation-provider',{attrs:{"name":_vm.$t('global.file'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('file-upload-picker',{attrs:{"on-create":"","storageType":"PAPER","error-messages":errors},on:{"send":function (cb) { return (_vm.onFileSend = cb); }},model:{value:(_vm.paper.url),callback:function ($$v) {_vm.$set(_vm.paper, "url", $$v)},expression:"paper.url"}})]}}])})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-menu',{ref:"issueDateCalendar",attrs:{"close-on-content-click":false,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"tertiary","label":_vm.$t('hr.paper.papers.issue_date'),"clearable":"","readonly":""},model:{value:(_vm.paper.issueDate),callback:function ($$v) {_vm.$set(_vm.paper, "issueDate", $$v)},expression:"paper.issueDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.issueDateCalendarState),callback:function ($$v) {_vm.issueDateCalendarState=$$v},expression:"issueDateCalendarState"}},[_c('v-date-picker',{attrs:{"locale":_vm.$store.state.language,"color":"primary","first-day-of-week":1,"scrollable":""},on:{"input":function($event){_vm.issueDateCalendarState = false}},model:{value:(_vm.paper.issueDate),callback:function ($$v) {_vm.$set(_vm.paper, "issueDate", $$v)},expression:"paper.issueDate"}})],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-menu',{ref:"expireDateCalendar",attrs:{"close-on-content-click":false,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"tertiary","label":_vm.$t('hr.paper.papers.expire_date'),"clearable":"","readonly":""},model:{value:(_vm.paper.expireDate),callback:function ($$v) {_vm.$set(_vm.paper, "expireDate", $$v)},expression:"paper.expireDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.expireDateCalendarState),callback:function ($$v) {_vm.expireDateCalendarState=$$v},expression:"expireDateCalendarState"}},[_c('v-date-picker',{attrs:{"color":"primary","scrollable":"","first-day-of-week":1,"locale":_vm.$store.state.language,"min":_vm.paper.issueDate},on:{"input":function($event){_vm.expireDateCalendarState = false}},model:{value:(_vm.paper.expireDate),callback:function ($$v) {_vm.$set(_vm.paper, "expireDate", $$v)},expression:"paper.expireDate"}})],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.note.description'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('hr.note.description'),"error-messages":errors},model:{value:(_vm.paper.description),callback:function ($$v) {_vm.$set(_vm.paper, "description", $$v)},expression:"paper.description"}})]}}])})],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('app-page-footer',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('v-btn',{attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.validateAndSave}},[_vm._v(_vm._s(_vm.$t("buttons.save")))])]},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }