import BaseModel from "@/base/model/BaseModel";

export default class Paper {
  constructor() {
    // super();
    this.paperType = null;
    this.employee = null;
    this.issueDate = null;
    this.expireDate = null;
    this.url = null;
    this.description = null;
  }
}
